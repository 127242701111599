.error-text {
  color: var(--ion-color-danger);
  font-size: small;
  right: 0;
  /* padding-left: 16px; */
}

ion-select,
input,
textarea {
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
}

/* .md ion-label {
  font-size: large !important;
  padding-bottom: 16px !important;
} */

.medium-text {
  font-size: medium !important;
}

.page-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10vh;
  left: 0;
  right: 0;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.max-form-width {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.full-width {
  width: 100%;
}

.spacer {
  height: 20px;
  background-color: var(--ion-color-light-tint);
}

.logo-auth {
  max-width: 100%;
  display: block;
}

.loading {
  font-size: medium;
  font-weight: 400 !important;
}

a {
  text-decoration: none;
}

.profileHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.avatar {
  border-radius: 10px !important;
  height: 8rem;
  width: 8rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media only screen and (min-width: 768px) {
  .show-on-small-and-down {
    display: none;
  }
}

.openai {
  font-size: larger;
}

:root {
  --ion-placeholder-color: red;
}


.quill{
  display: flex;
  flex-direction: column-reverse;
  border: 0;
}
.ql-editor{
  border: 0 !important;
  height: 100vh !important;
  max-height: 50vh !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
  border: 0 !important;
}

.ql-formats{
  border-right: 1px solid gray;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  height: 50vh;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.ion-page-invisible {
  opacity: 1 !important;
}